<template>
  <line-chart :chart-data="chartData" />
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TasksLastThirtyDaysChart",
  computed: {
    ...mapState({
      totalByDay: state => state.admin.stats.tasks.totalByDay
    }),
    last30Days() {
      const days = [];
      for (let index = 0; index < 30; index++) {
        days.push(this.$moment().subtract(index, "days"));
      }
      return days.reverse();
    },
    data() {
      if (!this.totalByDay)
        return this.$_.map(this.last30Days, () => {
          return 0;
        });
      return this.$_.map(this.last30Days, d => {
        return this.totalByDay[d.format("YYYYMMDD")] || 0;
      });
    },
    chartData() {
      return {
        labels: this.$_.map(this.last30Days, d => {
          return d.format("MMM Do");
        }),
        datasets: [
          {
            label: "Tasks",
            borderWidth: 2,
            borderColor: "rgba(0, 198, 206, 1)",
            backgroundColor: "rgba(0, 198, 206, 0.15)",
            data: this.data
          }
        ]
      };
    }
  }
};
</script>
