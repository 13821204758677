<template>
  <div
    class="columns is-marginless has-padding-50 has-padding-bottom-400 is-multiline is-mobile"
  >
    <div
      v-for="(stat, index) in statistics"
      :key="`stat-${index}`"
      class="column is-6-mobile is-6-tablet is-3-desktop"
    >
      <router-link :to="stat.route" class="box dashboard-stat">
        <span class="is-size-6-mobile is-size-5">{{ stat.label }}</span>

        <span class="is-size-3-mobile is-size-4 title">{{
          $_.isNumber(stat.value) ? stat.value : `&hellip;`
        }}</span>
      </router-link>
    </div>

    <div class="column is-12-mobile is-6-tablet">
      <collapse title="Sites added (last 30 days)">
        <sites-last-30-days-chart />
      </collapse>
    </div>

    <div class="column is-12-mobile is-6-tablet">
      <collapse title="Tasks added (last 30 days)">
        <tasks-last-30-days-chart />
      </collapse>
    </div>

    <div class="column is-12-mobile is-6-tablet">
      <collapse title="New users (last 30 days)">
        <users-last-30-days-chart />
      </collapse>
    </div>

    <div class="column is-12-mobile is-6-tablet">
      <collapse title="Contracts (all time)">
        <contracts-chart />
      </collapse>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AdminDashboard",
  components: {
    "sites-last-30-days-chart": require("./_sitesLast30DaysChart").default,
    "tasks-last-30-days-chart": require("./_tasksLast30DaysChart").default,
    "users-last-30-days-chart": require("./_usersLast30DaysChart").default,
    "contracts-chart": require("./_contractsChart").default
  },
  computed: {
    ...mapState({
      activeSites: state => state.admin.stats.sites.active,
      openTasks: state => state.admin.stats.tasks.open,
      activeUsers: state => state.admin.stats.users.active,
      activeContracts: state => state.admin.stats.contracts.active
    }),
    statistics() {
      return [
        {
          value: this.activeSites,
          label: "Sites",
          route: { path: "/admin/sites" }
        },
        {
          value: this.openTasks,
          label: "Tasks",
          route: { path: "/admin/tasks" }
        },
        {
          value: this.activeUsers,
          label: "Users",
          route: { path: "/admin/users" }
        },
        {
          value: this.activeContracts,
          label: "Contracts",
          route: { path: "/admin/contracts" }
        }
      ];
    }
  },
  created() {
    this.$store.dispatch(`admin/observeStats`, { statsId: `sites` });
    this.$store.dispatch(`admin/observeStats`, { statsId: `tasks` });
    this.$store.dispatch(`admin/observeStats`, { statsId: `users` });
    this.$store.dispatch(`admin/observeStats`, { statsId: `contracts` });
  },
  beforeDestroy() {
    this.$store.dispatch(`admin/unobserveStats`, { statsId: `tasks` });
    this.$store.dispatch(`admin/unobserveStats`, { statsId: `sites` });
    this.$store.dispatch(`admin/unobserveStats`, { statsId: `users` });
    this.$store.dispatch(`admin/unobserveStats`, { statsId: `contracts` });
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";
.box.dashboard-stat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @include mobile {
    span {
      width: 100%;
    }
  }
}
</style>
