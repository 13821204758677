<template>
  <doughnut-chart :chart-data="chartData" />
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ContractsChart",
  computed: {
    ...mapState({
      totalActive: state => state.admin.stats.contracts.active,
      totalOverdue: state => state.admin.stats.contracts.overdue,
      totalCancelled: state => state.admin.stats.contracts.cancelled,
      totalLapsed: state => state.admin.stats.contracts.lapsed
    }),
    chartData() {
      return {
        labels: ["Active", "Overdue", "Cancelled", "Lapsed"],
        datasets: [
          {
            label: "Contracts",
            borderWidth: 1,
            borderColor: [
              "rgba(0, 198, 206, 1)",
              "rgba(255, 56, 96, 1)",
              "rgba(219, 219, 219, 1)",
              "rgba(0, 0, 0, 0.3)"
            ],
            backgroundColor: [
              "rgba(0, 198, 206, 0.5)",
              "rgba(255, 56, 96, 0.5)",
              "rgba(219, 219, 219, 0.5)",
              "rgba(0, 0, 0, 0.15)"
            ],
            data: [
              this.totalActive,
              this.totalOverdue,
              this.totalCancelled,
              this.totalLapsed
            ]
          }
        ]
      };
    }
  }
};
</script>
